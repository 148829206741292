<template>
  <b-row>
    <b-col md="12">
      <validation-provider
        #default="{ errors }"
        rules="required"
      >
        <b-form-group
          :label="$t('Seguimento')"
          :class="errors.length > 0 ? 'is-invalid':null"
        >
          <quill-editor
            v-model="sw030s05"
            :options="{
              modules: {
                toolbar: '#quill-toolbar-edit-note',
              },
              placeholder: '',
              bounds: '#quill-toolbar-edit-note',
            }"
            class="quil-content border-bottom-0"
          />
          <div
            id="quill-toolbar-edit-note"
            class="quill-toolbar d-flex justify-content-end border-top-0"
          >
            <button
              v-b-tooltip.hover.top="$t('Negrito')"
              class="ql-bold"
            />
            <button
              v-b-tooltip.hover.top="$t('Itálico')"
              class="ql-italic"
            />
            <button
              v-b-tooltip.hover.top="$t('Sublinhado')"
              class="ql-underline"
            />
            <button
              v-b-tooltip.hover.top="$t('Lista')"
              class="ql-list"
              value="ordered"
            />
            <button
              v-b-tooltip.hover.top="$t('Lista numerada')"
              class="ql-list"
              value="bullet"
            />
            <button
              v-b-tooltip.hover.top="$t('Remover indentação')"
              class="ql-indent"
              value="-1"
            />
            <button
              v-b-tooltip.hover.top="$t('Indentar')"
              class="ql-indent"
              value="+1"
            />
            <button
              v-b-tooltip.hover.top="$t('Inserir link')"
              class="ql-link"
            />
            <button
              v-b-tooltip.hover.top="$t('Remover formatação')"
              class="ql-clean"
            />
          </div>
        </b-form-group>
      </validation-provider>
    </b-col>
  </b-row>
</template>

<script>
import {
  BFormGroup, BRow, BCol, VBTooltip,
} from 'bootstrap-vue'
import { ref } from '@vue/composition-api'
import { ValidationProvider } from 'vee-validate'
import { quillEditor } from '@/views/modules/components/editor-html'

export default {
  components: {
    BFormGroup,
    BRow,
    BCol,
    ValidationProvider,
    quillEditor,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  props: {
    row: {
      type: Object,
      default: () => {},
    },
  },
  methods: {
    clear() {
      this.sw030s05 = null
    },
    getData() {
      return {
        sw030s05: this.sw030s05,
      }
    },
  },
  setup(props) {
    let sw030s05 = null
    if ('sw030s05' in props.row) {
      sw030s05 = ref(props.row.sw030s05)
    } else {
      sw030s05 = ref('')
    }

    return {
      sw030s05,
    }
  },
}
</script>

<style lang="scss" scoped>
.quil-content ::v-deep {
  > .ql-container {
    border-bottom: 0;
  }

  + .quill-toolbar {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    border-bottom-left-radius: $border-radius;
    border-bottom-right-radius: $border-radius;
  }
}

::v-deep .ql-snow .ql-tooltip {
  white-space:normal!important;
}
</style>
